<template>
  <div>
   <!--  <div
      v-if="isAdd || isCustomize || isDelete || isSub"
      class="modal-backdrop show"
      @click="closeAll()"
    ></div> -->
    <kore-modal
      :value="isSub"
      title="Escolha uma opção"
      :hideactions="true"
      @closeAll="closeAll()"
      @confirm="closeAll()"
      @cancel="closeAll()"
    >
      <div>
        <div v-for="(s, sk) in isSub" :key="'sub-' + sk">
          <button
            @click="s.cb(tmp)"
            class="btn btn-primary"
            style="width: calc(100% - 20px); margin: 10px"
            type="submit"
          >
            <span>{{ s.name }}</span>
          </button>
        </div>
      </div>
    </kore-modal>

    <kore-modal
      :value="isDelete"
      title="Deletar NF de Material"
      actionButtonText="Deletar"
      @closeAll="closeAll()"
      @confirm="doDelete(deletes, true)"
      @cancel="doCancel()"
    >
      <div>
        Tem certeza que deseja excluir {{ deletes.length }} nota fiscal de
        material?
        <div v-if="isSend" class="alert alert-warning alert-icon">Por favor, aguarde...</div>
        <div v-if="isErrorMessage" class="alert alert-danger alert-icon">{{ isErrorMessage }}</div>
      </div>
    </kore-modal>

    <kore-modal
      :value="isAdd"
      title="Cadastrar NF de Material"
      @closeAll="closeAll()"
      @confirm="doSave()"
      @cancel="doCancel()"
      actionButtonText="Salvar"
    >
      <div>
        <v-container>
          <v-row>
            <template v-for="(i, k) in labels">
              <kore-select
                v-if="!i.hideInform"
                v-model="tmp[i.key]"
                :key="k"
                :valid.sync="i.valid"
                :label="i"
                :opts="opts"
                :editable="!isSend"
                :colSize="i.colSize"
                class="pl-4"
              ></kore-select>
            </template>
          </v-row>
        </v-container>

        <div v-if="isSend" class="alert alert-warning alert-icon">Por favor, aguarde...</div>
        <div v-if="isErrorMessage" class="alert alert-danger alert-icon">{{ isErrorMessage }}</div>
      </div>
    </kore-modal>

    <kore-modal
      :value="isCustomize"
      title="Customizar Campos"
      @closeAll="closeAll()"
      @confirm="closeAll()"
      @cancel="closeAll()"
    >
      <div>
        <div
          v-for="(i, k) in labels"
          :key="k"
          style="padding: 5px; border-bottom: silver 1px dotted"
        >
          <input type="checkbox" v-model="i.show" style="margin: 5px" />
          <div style="margin-top: 1px; float: right; width: calc(100% - 50px)">{{ i.name }}</div>
        </div>
      </div>
    </kore-modal>

    <!-- <div class="page_title">Notas Fiscais de Materiais</div> -->
    <div class="body">
      <kore-table
        has-filter
        :cols="labels"
        :opts="opts"
        :rows="fRows"
        :actionBarButtons="actionBarButtons"
        :loading="!loaded"
        :monthly-filter="competencia"
        @update:monthlyFilter="updateMonthlyFilter($event)"
        @contextmenu="actContext"
        @click="actContext"
        @rowstoprint="rowsToPrint"
        @load="doLoad()"
      ></kore-table>
    </div>
  </div>
</template>

<style scoped lang="scss">
.modal-dialog-scrollable .modal-body::-webkit-scrollbar {
  width: 3px;
}

.overflow {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 50;
}

.kmodal {
  background: white;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 60;
  transform: translate(-50%, -50%);
  min-width: 300px;
  min-height: 300px;
  border-radius: 5px;
  overflow: hidden;
  padding: 10px;
}

.actions {
  div {
    float: left;
    margin-right: 40px;
    cursor: pointer;
    font-weight: bold;
    color: #000000;

    img {
      margin: 5px;
    }
  }

  &:after {
    clear: both;
    content: " ";
  }
}

.filter {
  select {
    height: 33px;
    width: 100%;
  }
}
</style>


<script>
import _ from "lodash";
import { TipoImportacaoEnum } from "@/core/enums/importacoes";

export default {
  components: {
    "kore-modal": () => import("../../components/kore-modal.vue"),
    "kore-select": () => import("../../components/input-v.vue"),
    "kore-table": () => import("../../components/table-v.vue"),
  },
  watch: {
    competencia: function (value) {
      if (Array.isArray(value) && value.length === 2) {
        this.setFilters({ monthly: value });
        this.doLoad();
      }
    },
    "$route.params.clientId": function () {
      this.doLoad();
    },
  },
  created: function () {
    this.competencia = this.getFilters('monthly');
    this.resourceTerceiros.get().then((response) => {
      this.opts.terceiros = response.filter((t) => t.enquadramentoId == 10); //FIXME hardcoded
    });
    this.doLoad();
  },
  methods: {
    isValidForm() {
      var invalid = this.labels
        .filter((i) => !i.hideInform)
        .map((l) => (l.valid() ? "VALIDO" : "INVÁLIDO"))
        .find((valid) => valid == "INVÁLIDO");
      return !invalid;
    },
    actContext: function (v, e) {
      this.showContext({
        left: e.clientX,
        top: e.clientY,
        val: v,
        menu: this.options,
      });
    },
    doCancel: function () {
      this.isAdd = false;
      this.isDelete = false;
      this.isSend = false;
      this.tmp = {};
      this.isErrorMessage = "";
    },
    doSave: function () {
      if (this.isValidForm()) {
        if (this.isSend === false) {
          this.isSend = true;
          this.isErrorMessage = "";

          this.resource
            .save(this.tmp, this.tmp.id)
            .then((a) => {
              if (a.error) {
                this.isErrorMessage = a.error;
                this.isSend = false;
              } else {
                this.doCancel();
                setTimeout(this.doLoad, 1000);
              }
            })
            .catch(this.doCancel);
        }
      } else {
        this.isErrorMessage = "Campos do formulário estão inválidos.";
      }
    },
    doAdd: function () {
      this.isAdd = true;
    },
    doEdit: function (rows) {
      this.tmp = { ..._.find(this.rows, { id: rows.id }) };
      this.isAdd = true;
    },
    doDelete: function (rows, c = false) {
      if (c === false) {
        this.deletes = rows;
        this.isDelete = true;
      } else {
        this.isSend = true;

        this.resource
          .delete(rows.id)
          .then((a) => {
            if (a.error) {
              this.isErrorMessage = a.error;
              this.isSend = false;
            } else {
              this.doCancel();
              setTimeout(this.doLoad, 1000);
            }
          })
          .catch(this.doCancel);
      }
    },
    doCustomize: function () {
      this.isCustomize = true;
    },
    closeAll: function () {
      this.isAdd = false;
      this.isCustomize = false;
      this.isSub = false;
      this.isCustomize = false;
    },
    rowsToPrint: function (resolve) {
      resolve(this.fRows);
    },
    doLoad: function () {
      const [ competenciaIni, competenciaFim ] = this.competencia;
      this.loaded = false;
      this.resource.get({
          query: `competenciaIni=${competenciaIni}&competenciaFim=${competenciaFim}`,
        }).then((a) => {
        this.loaded = true;
        if (!a.error) {
          this.rows = a;
        }
      });
    },
    updateMonthlyFilter: function ($event) {
      this.competencia = $event;
    }
  },
  computed: {
    actionBarButtons: function () { 
      const buttons = [
        {
          text: "Adicionar",
          icon: "mdi-plus-box-outline",
          show: this.userHasAccessToAction("create"),
          action: () => {
            this.doAdd();
          },
        },
        {
          text: "Enviar Arquivos NF's de Materiais",
          icon: "mdi-file-import-outline",
          show: this.client.isClient ? this.userHasAccess("Importacoes.dispendios.planilhaDeMateriais") : this.userHasAccess("Importacoes.resource.index"),
          action: () => {
            const route = this.client.isClient
              ? { name: "listagem-importacoes-materiais-anexo" }
              : { name: "listagem-importacoes", query: { tipo: TipoImportacaoEnum.ANEXO_NF_MATERIAIS } };
            this.$router.push(route);
          },
        },
        {
          text: "Importar NF's de Materiais (Planilha)",
          icon: "mdi-file-import-outline",
          show: this.client.isClient ? this.userHasAccess("Importacoes.dispendios.anexosDeMateriais") : this.userHasAccess("Importacoes.resource.index"),
          action: () => {
            const route = this.client.isClient
              ? { name: "listagem-importacoes-materiais" }
              : { name: "listagem-importacoes", query: { tipo: TipoImportacaoEnum.FISCAL_NF_MATERIAIS } };
            this.$router.push(route);
          },
        },
      ];

      return buttons.filter(({ show }) => show);
    },
    client: function () {
      return this.getClient();
    },
    resource: function () {
      return this.apiResource(`/v1/fiscal/nfmaterial/${this.client.clientId}`);
    },
    resourceTerceiros: function () {
      return this.apiResource(`/v1/fiscal/terceiro/${this.client.clientId}/material`);
    },
    cLabels: function () {
      return _.filter(this.labels, function (l) {
        return l.type != 0 && l.type != -1;
      });
    },
    fRows: function () {
      var filters = {};

      if (this.filterCliente != 0 || this.filterServico != 0) {
        if (this.filterCliente != 0) {
          filters["id"] = this.filterCliente;
        }
        if (this.filterServico != 0) {
          filters["trabalho"] = this.filterServico;
        }

        return _.filter(this.rows, filters);
      } else {
        return this.rows;
      }
    },
    options: function () {
      const items = [
        {
          name: "Ver Itens da NF",
          limit: 1,
          class: { "btn-info": true, "btn-md": true },
          show: this.userHasAccessToAction("index"),
          cb: (row) => {
            this.$router.push({ name: "dispendio-materiais-detalhes", params: { nfId: row.id } });
          },
        },
        {
          name: "Editar",
          limit: 1,
          class: { "btn-warning": true, "btn-md": true },
          show: this.userHasAccessToAction("update"),
          cb: (e) => {
            this.doEdit(e);
          },
        },
        {
          name: "Excluir",
          limit: -1,
          class: { "btn-danger": true, "btn-md": true },
          show: this.userHasAccessToAction("delete"),
          cb: (e) => {
            this.doDelete(e);
          },
        },
      ];

      return items.filter(({ show }) => show);
    } 
  },
  data: function () {
    return {
      loaded: false,
      deletes: [],
      isSend: false,
      isErrorMessage: "",
      opts: {
        terceiros: [],
      },
      filterCliente: 0,
      filterServico: 0,
      isDelete: false,
      isAdd: false,
      isSub: false,
      isCustomize: false,
      tmp: {},
      rows: [],
      labels: [
        {
          key: "id",
          name: "ID",
          align: 1,
          hideInform: true,
          type: 0,
        },
        {
          key: "terceiroId",
          name: "Fornecedor",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "terceiros", key: "id", name: "razao_social" },
          rules: [{ rule: "required" }],
          colSize: 6,
        },
        {
          key: "nro_documento",
          name: "Número do Documento",
          align: -1,
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }],
          colSize: 6,
        },
        {
          key: "data",
          name: "Data",
          align: -1,
          type: this.$fieldTypes.DATE,
          rules: [{ rule: "required" }],
          colSize: 4,
        },
        {
          key: "competencia",
          name: "Competência",
          align: -1,
          type: this.$fieldTypes.MONTH,
          rules: [{ rule: "required" }],
          colSize: 4,
        },
        {
          key: "valor_nf",
          name: "Valor",
          align: 1,
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          rules: [{ rule: "required" }],
          colSize: 4,
        },
        {
          key: "observacao",
          name: "Observação",
          type: this.$fieldTypes.TEXTAREA,
          colSize: 12,
        },
        {
          key: "criado_em",
          name: "Criado Em",
          hideInTable: true,
          hideInform: true,
          type: this.$fieldTypes.DATETIME,
        },
        {
          key: "modificado_em",
          name: "Modificado Em",
          align: 1,
          hideInform: true,
          type: this.$fieldTypes.DATETIME,
        },
      ],
      competencia: [],
    };
  },
};
</script>